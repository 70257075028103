import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layout'
import Hero from '../components/layout/Hero'

const NotFound = ({ data }) => {
	const settings = {
		...JSON.parse(data.settings.content),
		navigation: data.navigation.edges.map(edge => edge.node) || [],
	}

	return (
		<Layout settings={settings}>
			<Hero title="Seite nicht gefunden." youtubeId="PMHQ20uZ-sk" isLarge />
		</Layout>
	)
}

export default NotFound

export const query = graphql`
query NotFoundQuery {
  	settings: storyblokEntry(field_component: {eq: "settings"}) {
		content
	}
	navigation: allStoryblokLink {
		edges {
		  node {
			id
			uuid
			slug
			parent_id
			name
			is_folder
			published
			is_startpage
			position
		  }
		}
	  }
}
`